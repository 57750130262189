import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import generateReferralLink from '../../utils/generateReferralLink';

const ReferralPopup = ({ showReferralPopup, setShowReferralPopup }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleReferralPopupClose = () => {
    setShowReferralPopup(false);
  };

  const referralLink = generateReferralLink(userInfo.user);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <Dialog
      open={showReferralPopup}
      onClose={handleReferralPopupClose}
      aria-labelledby="account-expired-dialog-title"
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: 'black',
          color: 'white',
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" color={'#FFF'}>
        Refer a talented masseuse
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleReferralPopupClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#FFF',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: '0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src="https://clubdynasty.ca/wp-content/uploads/2025/01/2142_Banner_for_inside_MA_Referral_Image2x.jpg"
            alt="Refer a masseuse, get 10 free sessions! Have them apply with your referral link, once they complete 10 shifts, you earn 10 free massages!"
            style={{
              width: '90%',
              height: 'auto',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              borderRadius: '10px',
            }}
          />
        </Box>

        {/* <DialogContentText
          mt={2}
          gutterBottom
          textAlign={'center'}
          color={'text.primary'}
        >
          Copy the referral link below and share it with a talented masseuse
        </DialogContentText> */}
        <TextField
          label="Referral Link"
          variant="outlined"
          value={referralLink}
          fullWidth
          color="primary"
          InputProps={{
            sx: { color: '#fff' },
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  aria-label="copy the referral link to the clipboard"
                  onClick={handleCopy}
                  edge="end"
                  startIcon={<ContentCopyIcon />}
                  variant="contained"
                  disableElevation
                >
                  COPY
                </Button>
              </InputAdornment>
            ),
          }}
          focused
          sx={{
            marginTop: 2,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleReferralPopupClose}
          sx={{
            marginRight: 2,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReferralPopup;
