import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMasseuseDetails from '../../hooks/useMasseuseDetails';
import stringToColor from '../../utils/stringToColor';

const ConversationFavoriteMasseuse = ({
  favorite,
  token,
  handleUnfavoritePopupOpen,
}) => {
  const navigate = useNavigate();
  const { masseuseDetails, isLoading, error } = useMasseuseDetails(
    favorite.masseuse,
    token
  );

  const handleDelete = (masseuseDetails, favorite_id) => {
    handleUnfavoritePopupOpen(masseuseDetails, favorite_id);
  };

  const openConversation = (masseuseDetails) => {
    navigate(
      `/conversations/${masseuseDetails?.id}?masseuse=${masseuseDetails?.name}`
    );
  };

  return isLoading ? (
    <ListItem>
      <Box sx={{ width: '100%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      </Box>
    </ListItem>
  ) : error ? (
    <TableRow>
      <Box sx={{ width: '100%' }}>
        <p>Masseuse is no longer with Club Dynasty</p>
      </Box>
    </TableRow>
  ) : (
    <ListItem
      secondaryAction={
        <Tooltip title="Unfavorite">
          <IconButton
            onClick={() => handleDelete(masseuseDetails, favorite.id)}
          >
            <RemoveCircleIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemButton
        sx={{ padding: '0' }}
        onClick={() => openConversation(masseuseDetails)}
      >
        <ListItemIcon>
          <Avatar
            src={masseuseDetails?.image}
            alt={masseuseDetails?.name}
            sx={{
              bgcolor: stringToColor(masseuseDetails?.name),
            }}
          >
            {masseuseDetails?.name[0]}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <Link to={`/masseuse/${masseuseDetails?.id}`}>
              <Typography variant="p" fontWeight={'bold'}>
                {masseuseDetails?.name}
              </Typography>
            </Link>
          }
          secondary={`Favorited on
            ${format(new Date(favorite.created), "MMMM d 'at' h:mm a")}`}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ConversationFavoriteMasseuse;
