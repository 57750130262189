import { useMemo } from 'react';

const useFilteredChatList = (chatList, searchQuery) => {
  return useMemo(() => {
    if (!chatList || !Array.isArray(chatList.pages)) return [];

    const lowercasedQuery = searchQuery.toLowerCase();

    const filteredChats = chatList.pages.map((group) => ({
      ...group,
      results: group.results.filter(
        (chat) =>
          chat.masseuse.name.toLowerCase().includes(lowercasedQuery) ||
          chat.content.toLowerCase().includes(lowercasedQuery)
      ),
    }));

    /*
    return filteredChats.some((group) => group.results.length > 0)
      ? filteredChats
      : chatList.pages;
    */
    return filteredChats;
  }, [chatList, searchQuery]);
};

export default useFilteredChatList;
