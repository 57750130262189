import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteFeedFavorite,
  getFeedFavoriteList,
} from '../../actions/feedActions';
import ConversationFavoriteMasseuse from './ConversationFavoriteMasseuse';

const ConversationFavoriteList = () => {
  const dispatch = useDispatch();

  const [unfavoritePopupOpen, setUnfavoritePopupOpen] = useState(false);
  const [masseuseToUnfavorite, setMasseuseToUnfavorite] = useState(null);
  const [favoriteId, setFavoriteId] = useState(null);

  const feedFavoriteList = useSelector((state) => state.feedFavoriteList);
  const { feedFavorites, loading: loadingFeedFavorites } = feedFavoriteList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!feedFavorites) {
      dispatch(getFeedFavoriteList());
    }
  }, [feedFavorites]);

  const handleUnfavoritePopupOpen = (masseuse, favorite_id) => {
    setUnfavoritePopupOpen(true);
    setMasseuseToUnfavorite(masseuse);
    setFavoriteId(favorite_id);
  };

  const handleUnfavoritePopupClose = () => {
    setUnfavoritePopupOpen(false);
    setMasseuseToUnfavorite(null);
    setFavoriteId(null);
  };

  const unfavoriteMasseuseFromFeed = (masseuse, favorite_id) => {
    dispatch(
      deleteFeedFavorite(
        favorite_id,
        setUnfavoritePopupOpen,
        masseuse,
        unfavoritePopupOpen
      )
    );
  };

  return (
    <Box mb={10}>
      <Dialog
        open={unfavoritePopupOpen}
        onClose={handleUnfavoritePopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {masseuseToUnfavorite ? (
            `Unfavorite ${masseuseToUnfavorite?.name}?`
          ) : (
            <Skeleton width={'30%'} />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {masseuseToUnfavorite ? (
              `If you unfavorite ${masseuseToUnfavorite?.name}, you will no longer receive notifications when she posts on feeds or becomes available.`
            ) : (
              <Box
                sx={{
                  width: '600px',
                }}
              >
                <Skeleton width={'100%'} />
                <Skeleton width={'70%'} />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnfavoritePopupClose}>Cancel</Button>
          <Button
            onClick={() =>
              unfavoriteMasseuseFromFeed(masseuseToUnfavorite, favoriteId)
            }
            autoFocus
          >
            Unfavorite
          </Button>
        </DialogActions>
      </Dialog>

      <List>
        {loadingFeedFavorites ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <ListItem key={index}>
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
              </ListItem>
            ))
        ) : feedFavorites && feedFavorites.results.length === 0 ? (
          <ListItem>
            <ListItemText secondary="You don’t have any favorite masseuses yet. Once you favorite a masseuse, they’ll appear here!" />
          </ListItem>
        ) : (
          feedFavorites &&
          feedFavorites.results.length > 0 &&
          feedFavorites.results.map((favorite, i) => (
            <ConversationFavoriteMasseuse
              key={i}
              favorite={favorite}
              token={userInfo.token}
              handleUnfavoritePopupOpen={handleUnfavoritePopupOpen}
            />
          ))
        )}
      </List>
    </Box>
  );
};

export default ConversationFavoriteList;
