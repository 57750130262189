import {
  Avatar,
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import generateReferralLink from '../../utils/generateReferralLink';

const ReferralLink = ({ user }) => {
  const referralLink = generateReferralLink(user);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  return (
    <Card
      variant="outlined"
      style={{
        border: '1px solid #c2872b66',
        borderRadius: '15px',
        padding: '20px',
        marginTop: '20px',
      }}
      className="profile-card-shadow"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography
            variant="h6"
            sx={{
              color: '#C2872B',
              fontWeight: '500',
              fontFamily: 'Poppins',
            }}
            gutterBottom
          >
            Masseuse Referral
          </Typography>
          <Typography variant="p" gutterBottom>
            Refer a masseuse, get 10 free sessions! Have them apply with your
            referral link, once they complete 10 shifts, you earn 10 free
            massages!
          </Typography>
          <TextField
            label="Referral Link"
            variant="outlined"
            value={referralLink}
            fullWidth
            color="primary"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    aria-label="copy the referral link to the clipboard"
                    onClick={handleCopy}
                    edge="end"
                    startIcon={<ContentCopyIcon />}
                    variant="contained"
                    disableElevation
                  >
                    COPY
                  </Button>
                </InputAdornment>
              ),
            }}
            focused
            sx={{
              marginTop: 2,
            }}
          />
        </Grid>
        {/* <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
          <Avatar src={last_visit} sx={{ width: 76, height: 76 }} />
        </Grid> */}
      </Grid>
    </Card>
  );
};

export default ReferralLink;
