import BlockIcon from '@mui/icons-material/Block';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Sensual90 from '@mui/icons-material/HistoryToggleOff';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { getMasseuseDetails } from '../../actions/masseuseActions';
import useAvailableMasseuses from '../../hooks/useAvailableMasseuses';
import Gallery from '../Gallery';
import {
  createFeedBlock,
  createFeedFavorite,
  deleteFeedBlock,
  deleteFeedFavorite,
  getFeedBlockList,
  getFeedFavoriteList,
} from '../../actions/feedActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ConversationInfo = ({ id, toolbarHeight, headerHeight, setView }) => {
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(false);
  const [masseuseFavorite, setMasseuseFavorite] = useState(null);
  const [masseuseBlock, setMasseuseBlock] = useState(null);

  const {
    availableMasseuses,
    isLoading: availableMasseusesLoading,
    error: availableMasseusesError,
  } = useAvailableMasseuses();

  const masseuseDetails = useSelector((state) => state.masseuseDetails);
  const { masseuse, loading: loadingDetails } = masseuseDetails;

  const feedFavoriteList = useSelector((state) => state.feedFavoriteList);
  const { feedFavorites, loading: loadingFeedFavorites } = feedFavoriteList;

  const feedBlockList = useSelector((state) => state.feedBlockList);
  const { feedBlocks, loading: loadingFeedBlocks } = feedBlockList;

  const feedBlockCreate = useSelector((state) => state.feedBlockCreate);
  const { success: successBlock } = feedBlockCreate;

  const feedBlockDelete = useSelector((state) => state.feedBlockDelete);
  const { success: successBlockDelete } = feedBlockDelete;

  useEffect(() => {
    dispatch(getMasseuseDetails(id));
  }, [id]);

  useEffect(() => {
    if (masseuse) {
      availableMasseuses?.some(
        (m) => m.id === masseuse.id && setIsOnline(true)
      );
    }
  }, [masseuse]);

  useEffect(() => {
    if (!feedFavorites) {
      dispatch(getFeedFavoriteList());
    } else {
      feedFavorites?.results.map((f) =>
        f.masseuse === masseuse?.id
          ? setMasseuseFavorite(f)
          : setMasseuseFavorite(null)
      );
    }
  }, [feedFavorites, masseuse]);

  useEffect(() => {
    if (!feedBlocks) {
      dispatch(getFeedBlockList());
    } else {
      feedBlocks?.results.map((f) =>
        f.masseuse === masseuse?.id
          ? setMasseuseBlock(f)
          : setMasseuseBlock(null)
      );
    }
  }, [feedBlocks, masseuse, successBlock, successBlockDelete]);

  const favoriteMasseuse = (masseuse) => {
    if (!masseuseFavorite) {
      dispatch(createFeedFavorite(masseuse, setMasseuseFavorite));
    } else if (masseuseFavorite) {
      dispatch(
        deleteFeedFavorite(
          masseuseFavorite.id,
          setMasseuseFavorite,
          masseuse.id,
          false
        )
      );
    }
  };

  const blockMasseuse = (masseuse) => {
    if (!masseuseBlock) {
      dispatch(createFeedBlock(masseuse, undefined, false));
    } else if (masseuseBlock) {
      dispatch(
        deleteFeedBlock(
          masseuse,
          masseuseBlock.id,
          undefined,
          false,
          setMasseuseBlock
        )
      );
    }
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${toolbarHeight + headerHeight}px)`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      aria-label="masseuse info"
    >
      {loadingDetails ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Skeleton variant="rectangular" width={'100%'} height={300} />
          <Skeleton width="100%" />
          <Skeleton width="70%" />
          <Skeleton width="40%" />
          <Skeleton variant="rectangular" width={'100%'} height={100} />
          <Skeleton width="100%" />
          <Skeleton width="70%" />
          <Skeleton width="40%" />
        </Box>
      ) : (
        masseuse && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <Gallery
              images={masseuse.images}
              thumbnails={masseuse.thumbnails}
            />
            <Typography
              variant="h6"
              component="div"
              style={{
                color: '#c3892b',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              mt={2}
            >
              <Link to={`/masseuse/${masseuse.id}`}>
                {masseuse.name.toUpperCase()}
              </Link>{' '}
              {masseuse.new && (
                <Tooltip title="New masseuse">
                  <img
                    src="https://clubdynasty.ca/wp-content/uploads/2022/01/new-purple-animation.gif"
                    width="30"
                    alt="New Masseuse"
                  ></img>
                </Tooltip>
              )}{' '}
              {masseuse.foot_massage && (
                <Tooltip title="Available for foot massages">
                  <img
                    src="https://clubdynasty.ca/wp-content/uploads/2022/11/foot_massage.gif"
                    width="30"
                    alt="Foot Massage"
                  ></img>
                </Tooltip>
              )}
              {masseuse.allow_lingam && (
                <Tooltip title="Available for lingam massages">
                  <img
                    src="https://clubdynasty.ca/wp-content/uploads/2024/07/lingam_icon.gif"
                    width="30"
                    alt="Foot Massage"
                  ></img>
                </Tooltip>
              )}{' '}
              {masseuse.sensual90 && (
                <Tooltip title="Available for 90-minute massages">
                  <Sensual90 sx={{ color: 'rgb(255, 140, 26)' }} />
                </Tooltip>
              )}
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              pt={2}
              gap={1}
            >
              <Button
                startIcon={<ArrowBackIcon />}
                size="small"
                variant="contained"
                disableElevation
                onClick={() => setView('chats')}
                sx={{
                  display: { xs: 'default', sm: 'none' },
                }}
              >
                Back
              </Button>
              {isOnline ? (
                <Chip
                  label="Online"
                  color="success"
                  variant="outlined"
                  size="small"
                  icon={<FiberManualRecordIcon fontSize="small" />}
                />
              ) : (
                <Chip
                  label="Offline"
                  color="error"
                  variant="outlined"
                  icon={<FiberManualRecordIcon />}
                />
              )}
              <Tooltip
                title={
                  masseuseFavorite ? 'Unfavorite Masseuse' : 'Favorite Masseuse'
                }
              >
                <IconButton
                  aria-label="settings"
                  onClick={() => favoriteMasseuse(masseuse)}
                >
                  <FavoriteIcon
                    color={masseuseFavorite ? 'error' : 'default'}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={masseuseBlock ? 'Unblock Masseuse' : 'Block Masseuse'}
              >
                <IconButton
                  aria-label="settings"
                  onClick={() => blockMasseuse(masseuse)}
                >
                  <BlockIcon color={masseuseBlock ? 'primary' : 'default'} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              display={'flex'}
              pt={2}
              sx={{
                width: '100%',
                borderBottom: '1px solid #f5f5f5',
              }}
            ></Box>
            {masseuse && (
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} gap={1} textAlign={'center'}>
                  {masseuse.age && (
                    <Chip
                      label={masseuse.age}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.background && (
                    <Chip
                      label={masseuse.background}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.language && (
                    <Chip
                      label={masseuse.language}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.technique && (
                    <Chip
                      label={masseuse.technique}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.tattoos && (
                    <Chip
                      label={masseuse.tattoos ? 'Yes' : 'No'}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.bust_enhanced && (
                    <Chip
                      label={masseuse.bust_enhanced ? 'Enhanced' : 'Natural'}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                  {masseuse.measurements && (
                    <Chip
                      label={masseuse.measurements}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}

                  {masseuse.hair_color && (
                    <Chip
                      label={masseuse.hair_color}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}

                  {masseuse.services && (
                    <Chip
                      label={masseuse.services}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}

                  {masseuse.height && (
                    <Chip
                      label={masseuse.height}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}

                  {masseuse.new && (
                    <Chip
                      label={'New masseuse'}
                      color="primary"
                      sx={{ mr: 1, mb: 1 }}
                      size="small"
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Box
              display={'flex'}
              pt={2}
              sx={{
                width: '100%',
                borderBottom: '1px solid #f5f5f5',
              }}
            ></Box>
            <Typography variant="body2" mt={1}>
              {masseuse?.bio}
            </Typography>{' '}
            <Box
              display={'flex'}
              pt={2}
              sx={{
                width: '100%',
                borderBottom: '1px solid #f5f5f5',
              }}
            ></Box>
            <Typography
              variant="h6"
              component="div"
              style={{
                color: '#c3892b',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Availabilities
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              pt={1}
              gap={1}
            >
              {masseuse ? (
                masseuse.schedule.map((s, i) => (
                  <Chip
                    key={i}
                    label={
                      format(new Date(s.start), 'eeee') ===
                      format(new Date(), 'eeee')
                        ? 'Today'
                        : `${format(new Date(s.start), 'eeee')}:
                                     ${format(new Date(s.start), 'p')}-
                                    ${format(new Date(s.end), 'p')}`
                    }
                    color="primary"
                  />
                ))
              ) : (
                <Chip label="N/A" color="error" />
              )}
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default ConversationInfo;
