// src/components/ConversationOnline.jsx

import React, { useMemo } from 'react';
import {
  Avatar,
  Stack,
  Box,
  Typography,
  styled,
  Badge,
  Skeleton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import useAvailableMasseuses from '../../hooks/useAvailableMasseuses';

function ConversationOnline({ searchQuery }) {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const {
    availableMasseuses,
    isLoading: availableMasseusesLoading,
    error: availableMasseusesError,
  } = useAvailableMasseuses();

  const filteredMasseuses = useMemo(() => {
    if (!availableMasseuses) return [];

    const lowercasedQuery = searchQuery.toLowerCase();
    const result = availableMasseuses.filter((masseuse) =>
      masseuse.name.toLowerCase().includes(lowercasedQuery)
    );

    // return result.length > 0 ? result : availableMasseuses;
    return result;
  }, [availableMasseuses, searchQuery]);

  return (
    <Box
      sx={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        p: 2,
      }}
    >
      {availableMasseusesLoading ? (
        <Stack direction="row" spacing={2}>
          {[...Array(20)].map((_, index) => (
            <Box key={index} textAlign="center">
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                <Skeleton variant="circular" width={56} height={56} />
              </StyledBadge>
              <Typography variant="caption" display="block">
                <Skeleton />
              </Typography>
            </Box>
          ))}
        </Stack>
      ) : (
        <Stack direction="row" spacing={2}>
          {filteredMasseuses.map(
            (masseuse) =>
              masseuse.technique !== 'Shiatsu' && (
                <Box key={masseuse.id} textAlign="center">
                  <Link
                    to={`/conversations/${masseuse.id}?masseuse=${masseuse.name}`}
                  >
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar
                        alt={masseuse.name}
                        src={masseuse.image}
                        sx={{ width: 56, height: 56 }}
                      />
                    </StyledBadge>
                    <Typography variant="caption" display="block">
                      {masseuse.name}
                    </Typography>
                  </Link>
                </Box>
              )
          )}
        </Stack>
      )}
    </Box>
  );
}

export default ConversationOnline;
