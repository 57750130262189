import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopupDetails } from '../actions/popupActions.js';
import Popup from './Popup.js';
import { shouldShowPopup } from '../utils/shouldShowPopup.js';
import NotificationPopup from './NotificationPopup.jsx';
import { shouldShowNotificationPopup } from '../utils/shouldShowNotificationPopup.js';
import BottomNotificationPopup from './BottomNotificationPopup.jsx';
import ReferralPopup from './Popups/ReferralPopup.jsx';

const PopupManager = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [notificationSnackBarShow, setNotificationSnackBarShow] =
    useState(false);

  const [showReferralPopup, setShowReferralPopup] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const popupDetails = useSelector((state) => state.popupDetails);
  const { popup, loadingPopup } = popupDetails;

  useEffect(() => {
    // Show popup every week if the user's email or phone is not verified
    const lastPopUpTime = localStorage.getItem('popUpTime'); // Retrieve the last popup time

    if (
      user &&
      !(user.email_verified === true || user.phone_verified === true) &&
      shouldShowPopup(lastPopUpTime) &&
      !user.fresh_login
    ) {
      dispatch(getPopupDetails(setModalOpen));
      localStorage.setItem('popUpTime', new Date().toISOString());
    }
  }, [user, dispatch, setModalOpen]);

  useEffect(() => {
    const lastPopUpTime = localStorage.getItem('notificationPopUpTime');

    if (
      shouldShowNotificationPopup(lastPopUpTime) &&
      user &&
      user.notification_configured === false &&
      (user.email_verified === true || user.phone_verified === true)
    ) {
      setNotificationModalOpen(true);
      localStorage.setItem('notificationPopUpTime', new Date().toISOString());
    }
  }, [user]);

  useEffect(() => {
    const notificationSnackbarShown = localStorage.getItem(
      'notificationSnackbarShown'
    );

    if (
      notificationSnackbarShown !== 'true' &&
      user &&
      user.notification_configured === false &&
      (user.email_verified === true || user.phone_verified === true)
    ) {
      //  set setNotificationSnackBarShow to true after 1 minute
      setTimeout(
        () => {
          setNotificationSnackBarShow(true);
          localStorage.setItem('notificationSnackbarShown', 'true');
        },
        // 1 minute
        60000
      );
    }

    // clean up the timeout
    return () => {
      clearTimeout();
    };
  }, [user]);

  useEffect(() => {
    const lastPopUpTime = localStorage.getItem('referralPopUpTime');

    if (shouldShowPopup(lastPopUpTime) && user) {
      setShowReferralPopup(true);
      localStorage.setItem('referralPopUpTime', new Date().toISOString());
    }
  }, [user]);

  return (
    <>
      {userInfo && (
        <Popup popup={popup} open={modalOpen} setOpen={setModalOpen} />
      )}
      {userInfo && userInfo.user && (
        <NotificationPopup
          open={notificationModalOpen}
          setOpen={setNotificationModalOpen}
        />
      )}
      {userInfo && userInfo.user && (
        <BottomNotificationPopup
          open={notificationSnackBarShow}
          setOpen={setNotificationSnackBarShow}
        />
      )}
      {userInfo && userInfo.user && (
        <ReferralPopup
          showReferralPopup={showReferralPopup}
          setShowReferralPopup={setShowReferralPopup}
        />
      )}
    </>
  );
};

export default PopupManager;
