import { Box, Grid, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import ConversationInterface from '../components/Conversation/ConversationInterface';
import ConversationInfo from '../components/Conversation/ConversationInfo';
import ConversationSearchAndListContainer from '../components/Conversation/ConversationSearchAndListContainer';
import useElementHeight from '../hooks/useElementHeight';
import { useSelector } from 'react-redux';

const ConversationWithScreen = () => {
  let { id } = useParams();

  const [view, setView] = React.useState('chats');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const masseuse = queryParams.get('masseuse');

  const appSettings = useSelector((state) => state.appSettings);
  const { headerHeight } = appSettings;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [toolbarRef, toolbarHeight] = useElementHeight();

  return (
    <Box mt={8}>
      <Toolbar
        ref={toolbarRef}
        sx={{
          backgroundColor: 'primary.main',
          width: '100%',
          zIndex: 2,
        }}
      >
        <Typography
          variant="p"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Chat with {masseuse}
        </Typography>
      </Toolbar>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            height: `calc(100vh - ${toolbarHeight + headerHeight}px)`,
            overflowY: 'auto',
            borderRight: '1px solid #CCCCCC',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <ConversationSearchAndListContainer />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: { xs: view === 'chats' ? 'block' : 'none', sm: 'block' },
          }}
        >
          <ConversationInterface
            id={id}
            toolbarHeight={toolbarHeight}
            headerHeight={headerHeight}
            setView={setView}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: { xs: view === 'info' ? 'block' : 'none', sm: 'block' },
            borderLeft: '1px solid #CCC',
          }}
        >
          <ConversationInfo
            id={id}
            toolbarHeight={toolbarHeight}
            headerHeight={headerHeight}
            setView={setView}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConversationWithScreen;
