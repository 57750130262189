import BlockIcon from '@mui/icons-material/Block';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MessageIcon from '@mui/icons-material/Message';
import {
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import SearchField from '../SearchField';
import ConversationBlockList from './ConversationBlockList';
import ConversationFavoriteList from './ConversationFavoriteList';
import ConversationList from './ConversationList';
import ConversationOnline from './ConversationOnline';
import { useSelector } from 'react-redux';
import useElementHeight from '../../hooks/useElementHeight';

const ConversationSearchAndListContainer = () => {
  const [view, setView] = React.useState('chats');
  const [searchQuery, setSearchQuery] = React.useState('');

  const appSettings = useSelector((state) => state.appSettings);
  const { headerHeight } = appSettings;

  const handleViewChange = (event, newValue) => {
    if (newValue !== null) {
      setView(newValue);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [toolbarRef, toolbarHeight] = useElementHeight();

  return (
    <Box
      sx={{
        height: `calc(100vh - ${toolbarHeight + headerHeight}px)`,
      }}
    >
      <SearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Divider />
      <Typography color={'primary'} fontWeight={'bold'} m={1}>
        Active now
      </Typography>
      <ConversationOnline searchQuery={searchQuery} />
      <Divider />
      <ToggleButtonGroup
        color="primary"
        value={view}
        exclusive
        onChange={handleViewChange}
        aria-label="Platform"
        fullWidth
        size="small"
      >
        <ToggleButton
          value="chats"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MessageIcon />
          Chats
        </ToggleButton>
        <ToggleButton
          value="favorites"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FavoriteIcon />
          Favorites
        </ToggleButton>
        <ToggleButton
          value="blocks"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <BlockIcon />
          <p>Blocks</p>
        </ToggleButton>
      </ToggleButtonGroup>
      <Divider />

      {view === 'chats' && (
        <>
          <Typography color={'primary'} fontWeight={'bold'} m={1}>
            Recent Chats
          </Typography>
          <ConversationList searchQuery={searchQuery} />
        </>
      )}
      {view === 'favorites' && (
        <>
          <Typography color={'primary'} fontWeight={'bold'} m={1}>
            Favorite Chats
          </Typography>
          <ConversationFavoriteList />
        </>
      )}
      {view === 'blocks' && (
        <>
          <Typography color={'primary'} fontWeight={'bold'} m={1}>
            Blocked Masseuses
          </Typography>
          <ConversationBlockList />
        </>
      )}
    </Box>
  );
};

export default ConversationSearchAndListContainer;
