import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import useMasseuseDetails from '../../hooks/useMasseuseDetails';
import stringToColor from '../../utils/stringToColor';

const ConversationBlockedMasseuse = ({
  block,
  token,
  handleUnblockPopupOpen,
}) => {
  const { masseuseDetails, isLoading, error } = useMasseuseDetails(
    block.masseuse,
    token
  );

  const handleDelete = (masseuseDetails, block_id) => {
    handleUnblockPopupOpen(masseuseDetails, block_id);
  };

  return isLoading ? (
    <ListItem>
      <Box sx={{ width: '100%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      </Box>
    </ListItem>
  ) : error ? (
    <TableRow>
      <Box sx={{ width: '100%' }}>
        <p>Masseuse is no longer with Club Dynasty</p>
      </Box>
    </TableRow>
  ) : (
    <ListItem
      secondaryAction={
        <Tooltip title="Unblock">
          <IconButton onClick={() => handleDelete(masseuseDetails, block.id)}>
            <RemoveCircleIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemIcon>
        <Link to={`/masseuse/${masseuseDetails?.id}`}>
          <Avatar
            src={masseuseDetails?.image}
            alt={masseuseDetails?.name}
            sx={{
              bgcolor: stringToColor(masseuseDetails?.name),
            }}
          >
            {masseuseDetails?.name[0]}
          </Avatar>
        </Link>
      </ListItemIcon>
      <ListItemText
        primary={
          <Link to={`/masseuse/${masseuseDetails?.id}`}>
            <Typography variant="p" fontWeight={'bold'}>
              {masseuseDetails?.name}
            </Typography>
          </Link>
        }
        secondary={`Blocked on
                ${format(new Date(block.created), "MMMM d 'at' h:mm a")}`}
      />
    </ListItem>
  );
};

export default ConversationBlockedMasseuse;
