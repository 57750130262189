import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';

const API_URI = 'https://mydynasty.ca/cd/api/v1';

const useConversations = (id, userInfo) => {
  const config = {
    headers: {
      Authorization: userInfo.token,
    },
  };

  const queryClient = useQueryClient();

  // Fetch conversations with infinite scrolling and auto-refetch every 30 seconds
  const {
    data: chats,
    error: chatsError,
    isLoading: isChatsLoading,
    fetchNextPage,
    hasNextPage,
    refetch: refetchChats,
  } = useInfiniteQuery(
    ['chats', id],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(
        `${API_URI}/conversations/with/${id}/?page=${pageParam}&page_size=10`,
        config
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next ?? false, // Determine the next page from API response
      refetchInterval: 30000, // Refetch every 30 seconds
    }
  );

  // Post a new message
  const mutation = useMutation(
    async (newMessage) => {
      await axios.post(`${API_URI}/conversations/`, newMessage, config);
    },
    {
      onSuccess: () => {
        // Refetch conversations after successful post
        queryClient.invalidateQueries(['chats', id]);
      },
    }
  );

  return {
    chats,
    chatsError,
    isChatsLoading,
    fetchNextPage,
    hasNextPage,
    refetchChats,
    mutation,
  };
};

export default useConversations;
