function generateReferralLink(user) {
  const baseUrl = 'https://clubdynasty.ca/new-masseuse/';

  // Create a referral identifier (can be user ID, email hash, or UUID)
  const referralId = btoa(`${user.name}, ${user.email}, ${user.phone}`); // Base64 encoding the email for obfuscation

  // Construct query params
  const params = new URLSearchParams({
    ref: referralId, // This will be used to track referrals
  });

  // Generate the full referral link
  return `${baseUrl}?${params.toString()}`;
}

export default generateReferralLink;
