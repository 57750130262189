import { useState, useEffect, useRef } from 'react';

const useElementHeight = () => {
  const ref = useRef(null); // Reference to the element
  const [height, setHeight] = useState(0); // State to store height

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    };

    updateHeight(); // Set height initially

    window.addEventListener('resize', updateHeight); // Listen for resize

    return () => window.removeEventListener('resize', updateHeight); // Cleanup
  }, []);

  return [ref, height]; // Return ref and height
};

export default useElementHeight;
