import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { API_URI } from '../../constants/appConstants';
import useFilteredChatList from '../../hooks/useFilteredChatList';
import stringToColor from '../../utils/stringToColor';
import useAvailableMasseuses from '../../hooks/useAvailableMasseuses';
import { shuffle } from 'lodash';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const ConversationList = ({ searchQuery }) => {
  const location = useLocation();

  const {
    availableMasseuses,
    isLoading: availableMasseusesLoading,
    error: availableMasseusesError,
  } = useAvailableMasseuses();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      Authorization: userInfo.token,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    isLoading: isChatListLoading,
    error: chatListError,
    data: chatList,
    fetchNextPage,
  } = useInfiniteQuery(
    ['chatList'],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(
        `${API_URI}/conversations/?page=${pageParam}&page_size=10`,
        config
      );

      // const obj = {
      //   id: 18,
      //   masseuse: {
      //     name: 'MasseuseTest',
      //     id: 38140,
      //     image: '',
      //   },
      //   created: '2025-01-28T09:31:11.346066-05:00',
      //   updated: '2025-01-28T09:31:11.346079-05:00',
      //   content:
      //     'Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis. 7 days',
      //   received: null,
      //   deleted: null,
      // };

      // for (let i = 0; i < 20; i++) {
      //   data.results.push(obj);
      // }

      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.next ?? false,
    }
  );

  const filteredChatList = useFilteredChatList(chatList, searchQuery);

  return (
    <Box
      sx={{
        minHeight: '80vh',
      }}
    >
      {filteredChatList && filteredChatList[0]?.results?.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            overflow: 'auto',
          }}
          p={2}
          mt={4}
        >
          <AvatarGroup total={availableMasseuses?.length}>
            {availableMasseuses?.map(
              (masseuse) =>
                masseuse.technique !== 'Shiatsu' && (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                    key={masseuse.id}
                  >
                    <Avatar alt={masseuse.name} src={masseuse.image} />
                  </StyledBadge>
                )
            )}
          </AvatarGroup>

          <Typography
            variant="p"
            gutterBottom
            textAlign={'center'}
            fontSize={{
              xs: '0.9rem',
              sm: '1rem',
            }}
            mt={1}
          >
            You haven't had any conversations yet. <br /> Click on an active
            masseuse above to start chatting!
          </Typography>
        </Box>
      ) : (
        <List>
          <InfiniteScroll
            dataLength={chatList?.pages?.slice(-1)[0].last ?? 5}
            next={fetchNextPage}
            hasMore={
              chatList?.pages.slice(-1)[0].total ===
              chatList?.pages.slice(-1)[0].current
                ? false
                : true
            }
            endMessage={
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: 'block', textAlign: 'center' }}
                color={'text.disabled'}
                mt={2}
              >
                You have reached the end of the chat list
              </Typography>
            }
            inverse={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {filteredChatList?.map((group, i) => {
              return group.results.map((chat, j) => {
                return (
                  <React.Fragment key={j}>
                    <Link
                      to={`/conversations/${chat.masseuse.id}?masseuse=${chat.masseuse.name}`}
                    >
                      <ListItem
                        disablePadding
                        selected={
                          location.pathname.includes(chat.masseuse.id)
                            ? true
                            : false
                        }
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <Avatar
                              alt={chat.masseuse.name}
                              src={chat.masseuse.image}
                              sx={{
                                bgcolor: stringToColor(chat.masseuse.name),
                              }}
                            >
                              {chat.masseuse.name[0]}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography variant="p" fontWeight={'bold'}>
                                  {chat.masseuse.name}
                                </Typography>
                                <Typography variant="caption">
                                  {formatDistanceToNow(new Date(chat.created), {
                                    addSuffix: true,
                                  })}
                                </Typography>
                              </span>
                            }
                            secondary={
                              chat.received
                                ? chat.content
                                : `You: ${chat.content}`
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                    {i < chatList?.pages[0].total - 1 && <Divider />}
                  </React.Fragment>
                );
              });
            })}
          </InfiniteScroll>
        </List>
      )}
    </Box>
  );
};

export default ConversationList;
