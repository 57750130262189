import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFeedBlock, getFeedBlockList } from '../../actions/feedActions';
import ConversationBlockedMasseuse from './ConversationBlockedMasseuse';

const ConversationBlockList = () => {
  const dispatch = useDispatch();

  const [unblockPopupOpen, setUnblockPopupOpen] = useState(false);
  const [masseuseToUnblock, setMasseuseToUnblock] = useState(null);
  const [blockId, setBlockId] = useState(null);

  const feedBlockList = useSelector((state) => state.feedBlockList);
  const { feedBlocks, loading: loadingFeedBlocks } = feedBlockList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!feedBlocks) {
      dispatch(getFeedBlockList());
    }
  }, [feedBlocks]);

  const handleUnblockPopupOpen = (masseuse, block_id) => {
    setUnblockPopupOpen(true);
    setMasseuseToUnblock(masseuse);
    setBlockId(block_id);
  };

  const handleUnblockPopupClose = () => {
    setUnblockPopupOpen(false);
    setMasseuseToUnblock(null);
    setBlockId(null);
  };

  const unblockMasseuseFromFeed = (masseuse, block_id) => {
    dispatch(deleteFeedBlock(masseuse, block_id, handleUnblockPopupClose));
  };

  return (
    <Box mb={10}>
      <Dialog
        open={unblockPopupOpen}
        onClose={handleUnblockPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {masseuseToUnblock ? (
            `Unblock ${masseuseToUnblock?.name}?`
          ) : (
            <Skeleton width={'30%'} />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {masseuseToUnblock ? (
              `Blocks are sitewide. If you unblock ${masseuseToUnblock?.name}, you'll be able to message her here and see her posts in the feed.`
            ) : (
              <Box
                sx={{
                  width: '600px',
                }}
              >
                <Skeleton width={'100%'} />
                <Skeleton width={'70%'} />
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnblockPopupClose}>Cancel</Button>
          <Button
            onClick={() => unblockMasseuseFromFeed(masseuseToUnblock, blockId)}
            autoFocus
          >
            Unblock
          </Button>
        </DialogActions>
      </Dialog>
      <List>
        {loadingFeedBlocks ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <ListItem key={index}>
                <Box sx={{ width: '100%' }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
              </ListItem>
            ))
        ) : feedBlocks && feedBlocks.results.length === 0 ? (
          <ListItem>
            <ListItemText secondary="You don’t have any blocked masseuses yet. Once you block a masseuse, they’ll appear here!" />
          </ListItem>
        ) : (
          feedBlocks &&
          feedBlocks.results.length > 0 &&
          feedBlocks.results.map((block, i) => (
            <ConversationBlockedMasseuse
              key={i}
              block={block}
              token={userInfo.token}
              handleUnblockPopupOpen={handleUnblockPopupOpen}
            />
          ))
        )}
      </List>
    </Box>
  );
};

export default ConversationBlockList;
