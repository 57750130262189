import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { format, formatDistanceToNow } from 'date-fns';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useConversations from '../../hooks/useConversation';
import useImageSrc from '../../hooks/useImageSrc';
import useMasseuseDetails from '../../hooks/useMasseuseDetails';
import InfoIcon from '@mui/icons-material/Info';

// Chat Bubble Component
const ChatBubble = ({
  message,
  isUser,
  timestamp,
  user,
  imageSrc,
  masseuse,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: isUser ? 'flex-end' : 'flex-start',
      marginY: 1,
    }}
  >
    {!isUser && (
      <Link to={`/masseuse/${masseuse.id}`}>
        <Avatar
          sx={{ marginRight: 1 }}
          src={masseuse.image}
          alt={masseuse.name}
        >
          {masseuse.name.charAt(0)}
        </Avatar>
      </Link>
    )}
    <Box
      sx={{
        maxWidth: '70%',
        backgroundColor: isUser ? '#f0f0f0' : '#D9A566',
        color: isUser ? '#000' : '#fff',
        padding: '8px 12px',
        borderRadius: '12px',
        borderTopLeftRadius: isUser ? '12px' : '0',
        borderTopRightRadius: isUser ? '0' : '12px',
      }}
    >
      <Tooltip title={format(new Date(timestamp), 'PPp')} placement="top">
        <Typography variant="body2">
          {message} <br />
          <span style={{ fontSize: '0.75rem', color: 'gray' }}>
            {formatDistanceToNow(new Date(timestamp), {
              addSuffix: false,
            }).replace('about ', '')}
          </span>
        </Typography>
      </Tooltip>
    </Box>
    {isUser && (
      <Link to={`/profile`}>
        <Avatar sx={{ marginLeft: 1 }} src={imageSrc} alt={user.name}>
          {user.name.charAt(0)}
        </Avatar>
      </Link>
    )}
  </Box>
);

// Chat Interface Component
const ConversationInterface = ({
  id,
  toolbarHeight,
  headerHeight,
  setView,
}) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const {
    chats,
    isChatsLoading,
    chatsError,
    fetchNextPage,
    hasNextPage,
    mutation,
  } = useConversations(id, userInfo);

  const handleSendMessage = (e) => {
    e.preventDefault();
    mutation.mutate({
      content: message,
      receiver: 38140,
    });
    setMessage('');
  };

  const imageSrc = useImageSrc(
    userInfo?.token ?? '',
    userInfo?.user.avatar
      ? `https://mydynasty.ca/cd/api/v1/${userInfo?.user.avatar}`
      : ''
  );

  const {
    masseuseDetails,
    isLoading: isMasseuseDetailsLoading,
    error,
  } = useMasseuseDetails(id, userInfo?.token);

  const handleChatlistNavigate = () => {
    navigate(`/conversations`);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${toolbarHeight + headerHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        overflow: 'hidden',
      }}
    >
      {chats && chats.pages[0].length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            overflow: 'auto',
          }}
          p={2}
          mt={1}
        >
          <Avatar
            sx={{ width: 100, height: 100, marginBottom: 1 }}
            src={masseuseDetails?.image}
          />
          <Typography
            variant="h4"
            color={'primary'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
            gutterBottom
          >
            {masseuseDetails?.name}
          </Typography>
          <Typography
            variant="p"
            gutterBottom
            textAlign={'center'}
            fontSize={{
              xs: '0.9rem',
              sm: '1rem',
            }}
          >
            Start a chat with {masseuseDetails?.name} by sending her a message.
            Be mindful of the following:
          </Typography>
          <ul
            style={{
              marginTop: '10px',
              fontSize: '0.8rem',
              color: '#666',
            }}
          >
            <li>Be polite and respectful at all times</li>
            <li>Keep the conversation about massage services only</li>
            <li>No rude or inappropriate messages</li>
            <li>If they don’t reply right away, give them some time</li>
            <li>
              Confirm details like pricing and availability before booking.
            </li>
            <li>Respect privacy and don’t ask for personal info.</li>
          </ul>
        </Box>
      ) : (
        <Box
          id="scrollableDiv"
          style={{
            height: {
              xs: '80%',
              sm: '85%',
            },
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          p={1}
        >
          <InfiniteScroll
            dataLength={chats?.pages?.slice(-1)[0].last ?? 5}
            next={fetchNextPage}
            hasMore={
              chats?.pages.slice(-1)[0].total ===
              chats?.pages.slice(-1)[0].current
                ? false
                : true
            }
            endMessage={
              <Typography
                variant="caption"
                gutterBottom
                sx={{ display: 'block', textAlign: 'center' }}
                color={'text.disabled'}
                mt={2}
              >
                You have reached the end of the chat list
              </Typography>
            }
            style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            inverse={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {chats?.pages.map((group, i) => {
              return group.map((chat, i) => {
                return (
                  <ChatBubble
                    key={chat.id}
                    message={chat.content}
                    isUser={chat.received === null ? true : false}
                    timestamp={chat.created}
                    user={userInfo.user}
                    imageSrc={imageSrc}
                    masseuse={chat.masseuse}
                  />
                );
              });
            })}
          </InfiniteScroll>
        </Box>
      )}

      <Box
        component={'form'}
        onSubmit={handleSendMessage}
        sx={{
          height: {
            xs: '20%',
            sm: '15%',
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
          borderTop: '1px solid #ccc',
          backgroundColor: '#fff',
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ flex: 1 }}
          required
          fullWidth
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
          mt={1}
          mb={1}
        >
          <Button
            variant="contained"
            disableElevation
            startIcon={<ArrowBackIcon />}
            size="small"
            onClick={handleChatlistNavigate}
          >
            Chat list
          </Button>
          <IconButton
            color="primary"
            onClick={() => setView('info')}
            sx={{
              display: { xs: 'default', sm: 'none' },
            }}
          >
            <InfoIcon />
          </IconButton>
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            disableElevation
            size="small"
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationInterface;
