// src/screens/ConversationScreenNew.jsx

import { Box, Container, Toolbar, Typography } from '@mui/material';
import React from 'react';
import ConversationSearchAndListContainer from '../components/Conversation/ConversationSearchAndListContainer';

const ConversationScreen = () => {
  return (
    <Box mt={8}>
      <Toolbar
        sx={{
          backgroundColor: 'primary.main',
          width: '100%',
        }}
      >
        <Typography
          variant="p"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'left',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Chat with the girls
        </Typography>
      </Toolbar>
      <Container>
        <ConversationSearchAndListContainer />
      </Container>
    </Box>
  );
};

export default ConversationScreen;
